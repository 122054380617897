<template>
  <div :class="'min-h-screen bg-gradient-to-br flex items-center justify-center p-6  ' + backgroundColorCss">
    <div class="w-full max-w-md bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-xl shadow-lg overflow-hidden">
      <div class="p-8">
        <div class="flex flex-col items-center mb-6">
          <img
            src="https://avatars.githubusercontent.com/u/58356637?v=4"
            alt="Profile Picture" class="w-32 h-32 rounded-full border-4 border-white shadow-lg mb-4"
            @click="toggleMusic"
          />
          <h1
            class="text-3xl font-bold text-white mb-2"
          >
            Achille
          </h1>
          <p class="text-lg text-white text-center">
            Student | Android enthusiast | Tools dev
          </p>
        </div>
          
          <div class="grid grid-cols-2 gap-4">
            <a
              href="https://github.com/0xsharkboy"
              class="flex items-center justify-center bg-white bg-opacity-30 hover:bg-opacity-40 text-white rounded-lg p-3 transition duration-300 ease-in-out"
            >
              <GithubIcon class="h-6 w-6 mr-2" />
              <span>GitHub</span>
            </a>
            <a
              href="https://xdaforums.com/m/0xsharkboy.12237521/#recent-content"
              class="flex items-center justify-center bg-white bg-opacity-30 hover:bg-opacity-40 text-white rounded-lg p-3 transition duration-300 ease-in-out"
            >
              <CodeIcon class="h-6 w-6 mr-2" />
              <span>XDA</span>
            </a>
            <a
              href="https://ko-fi.com/0xsharkboy"
              class="flex items-center justify-center bg-white bg-opacity-30 hover:bg-opacity-40 text-white rounded-lg p-3 transition duration-300 ease-in-out"
            >
              <CoffeeIcon class="h-6 w-6 mr-2" />
              <span>Ko-Fi</span>
            </a>
            <a
              href="https://t.me/Oxsharkboy"
              class="flex items-center justify-center bg-white bg-opacity-30 hover:bg-opacity-40 text-white rounded-lg p-3 transition duration-300 ease-in-out"
            >
              <SendIcon class="h-6 w-6 mr-2" />
              <span>Contact Me</span>
            </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GithubIcon, CodeIcon, CoffeeIcon, SendIcon } from 'lucide-vue-next'

export default {
  components: {
    GithubIcon,
    CodeIcon,
    CoffeeIcon,
    SendIcon
  },
  data() {
    return {
      backgroundColorCss: '',
      backgroundColors: [
        'from-purple-800 via-pink-700 to-red-600',
        'from-blue-900 via-teal-800 to-green-700',
        'from-blue-800 via-blue-700 to-blue-600'
      ],
      audio: null
    }
  },
  created () {
    if (sessionStorage.getItem('backgroundColor')) {
      this.backgroundColorCss = sessionStorage.getItem('backgroundColor')
    } else {
      const randomIndex = this.getRandomInt(this.backgroundColors.length)
  
      this.backgroundColorCss = this.backgroundColors[randomIndex]
      sessionStorage.setItem('backgroundColor', this.backgroundColorCss)
    }
  },
  mounted() {
    this.audio = new Audio(require('../assets/audio/DeathNoteTheme.mp3'))
    this.audio.volume = 0.3
    this.audio.loop = true
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    toggleMusic() {
      if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}
</style>