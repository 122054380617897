<template>
  <PersonalIntroCard />
</template>

<script>
import PersonalIntroCard from './components/PersonalIntroCard.vue'

export default {
  name: 'App',
  components: {
    PersonalIntroCard
  },
  created () {
    document.title = '0xSharkBoy'
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>